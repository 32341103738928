<template>
    <div class="row">
        <div class="col-md-12" v-if="loading">
            <div class="row mt-5 pt-5">
                <div class="col-md-12" v-loading="loading">

                </div>
            </div>
        </div>
        <div class="col-md-12" v-if="!loading">
            <el-table :data="tableData" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }" style="width: 100%"
                :empty-text="'No Data Available'" height="60vh">
                <el-table-column label="Message" prop="message" width="">

                </el-table-column>
                <el-table-column label="Status" prop="status" width="">
                    <template slot-scope="scope">
                        <span style="color:#36D962" v-if="scope.row.status == 2">Birthday</span>
                         <span style="color:#f84d4d" v-if="scope.row.status == 3">Anniversary</span>
                    </template>
                </el-table-column>
                <el-table-column label="Action" width="200">
                    <template slot-scope="scope">
                        <!-- <button id="enter__account" class="approve" @click="unpublishNotification(scope)">
                            Unpublish
                        </button> -->
                        <button class="text-danger" @click="deleteNotification(scope)">
                            Delete
                        </button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ActionModal ref="actionModal" :payload="payload" @close="closedModal" />
        <NotificationsModal ref="notificationModal" :payload="payload" @close="closedModal" />
        <ActionModal ref="unpublishModal" :payload="payload" @close="closedModal" />
    </div>
</template>

<script>
import $ from 'jquery'
import store from '@/state/store.js'
import moment from 'moment'
import appConfig from '@/app.config'
import NotificationsModal from './NoficationModal'

export default {
    components: {
        NotificationsModal
    },
    page: {
        title: 'Notification history',
        meta: [{ name: 'description', content: appConfig.description }],

    },
    data() {
        return {
            loading: false,
            payload: {
                type: ''
            }
        }
    },
    computed: {
        tableData() {
            return store.getters['admin/notifications/notifications'].filter((data) => {
                return data.status == 2 || data.status == 3
            })
        }
    },
    methods: {
        openNotificationModal() {
            let element = this.$refs.notificationModal.$el
            $(element).modal('show')
        },
        openActionModal() {
            let element = this.$refs.actionModal.$el
            $(element).modal('show')
        },
        publish(data) {
            this.payload = {
                type: 'publish',
                data: data,
            }
            this.openNotificationModal()
        },
        openUnpublishModal() {
            let element = this.$refs.unpublishModal.$el
            $(element).modal('show')

        },

        deleteNotification(message) {
            this.payload = {
                message: 'Are you sure you want to delete this notification?',
                action: 'deleteNotification',
                actionMessage: 'Delete',
                data: message.row,
                iconType: 'delete',
                index: message.$index
            }
            this.openActionModal()
        },
        unpublishNotification(message) {
            this.payload = {
                message: 'Are you sure you want to unpublish this notification?',
                action: 'unpublishNotification',
                actionMessage: 'Unpublish',
                data: message.row,
                iconType: 'delete',
                index: message.$index
            }

            this.openUnpublishModal()
        },
        closedModal() {
            let element = this.$refs.unpublishModal.$el
            $(element).modal('hide')

            let element2 = this.$refs.actionModal.$el
            $(element2).modal('hide')

            this.loading = true
            store.dispatch('admin/notifications/getNotifications')
                .finally(() => this.loading = false)
        }


    },
    mounted() {
        this.loading = true
        store.dispatch('admin/notifications/getNotifications')
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped>
#enter__account {
    color: #F7921C;
}

button {
    outline: none;
    border: none;
    background: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
}
</style>